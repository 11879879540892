<template>
  <layout-base :class="$style.navigation">
    <div :class="$style.sidebar">
      <div
        :class="[$style.sidebarItem, $style.sidebarItemInactive]"
        title="Поиск"
      >
        <img src="@/assets/images/icons/search.svg">
      </div>
      <div
        :class="[$style.sidebarItem, $style.sidebarItemInactive]"
        title="О программе"
      >
        <img src="@/assets/images/icons/info.svg">
      </div>
      <div
        :class="[$style.sidebarItem, $style.sidebarItemInactive]"
        title="Справка"
      >
        <img src="@/assets/images/icons/document.svg">
      </div>
      <div
        :class="$style.sidebarItem"
        title="Выход"
        @click="logout"
      >
        <img src="@/assets/images/icons/logout.svg">
      </div>
    </div>
    <div>
      <block-navigation />
    </div>
    <div :class="$style.content">
      <loader
        v-show="isLoaderNeeded"
        :class="$style.loader"
      />
      <slot />
    </div>
  </layout-base>
</template>

<script>
import LayoutBase from '@/layouts/base.vue';
import BlockNavigation from '@/components/layouts/navigation/block-navigation.vue';
import Loader from '@/components/common/blocks/loader.vue';

export default {
  components: {
    LayoutBase,
    BlockNavigation,
    Loader,
  },
  computed: {
    isLoaderNeeded() {
      return this.$store.getters['loader/isLoaderNeeded'];
    },
  },
  methods: {
    logout() {
      return this.$store
        .dispatch('user/logout')
        .then(() => this.$router.push({ name: 'user-login' }));
    },
  },
};
</script>

<style lang="scss" module>
.navigation {
  gap: 20px;
}

.sidebar {
  background: $white;
  padding-top: 23px;
  min-width: 60px;
}

.sidebarItem {
  padding: 15px 0;
  cursor: pointer;

  &Inactive {
    // Временно
    user-select: none;
    cursor: not-allowed;
    opacity: 0.3;
  }

  img {
    margin: 0 auto;
    display: block;
  }
}

.loader {
  width: calc(100% - 370px);
  position: absolute;
  height: 100%;
  background: white;
  z-index: 50000;
}

.content {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  flex-grow: 1;
  display: inline-flex;
  max-width: calc(100% - 30px);
}
</style>
