<template>
  <div :class="$style.navigation">
    <navigation
      v-for="item in menu"
      :key="item.slug"
      :slug="item.slug"
      :name="item.name"
      :opened="opened[item.slug]"
      :categories="item.categories"
      @toggle="toggle(item.slug)"
    />
  </div>
</template>

<script>
import Navigation from './navigation.vue';

const menu = [
  {
    name: 'Отчеты',
    slug: 'reports',
    categories: [
      {
        name: 'Работа пользователей',
        slug: 'user',
        children: [
          { name: 'Суммарный по рабочему времени', route: 'consolidated' },
          {
            name: 'Табель рабочего времени ',
            route: 'worktime',
            children: [
              { name: 'Тепловая карта', route: 'heatMap' },
              { name: 'Детализация', route: 'worktimeDetails' },
              { name: 'Нарушения трудовой дисциплины', route: 'disciplineViolations' },
            ],
          },
          { name: 'Продуктивность работы', route: 'productivity' },
          { name: 'Время работы ПК и приложений', route: 'appsTime' },
          { name: 'Работа с программами', route: 'programs' },
          { name: 'Лента активности', route: 'activityFeed' },
        ],
      },
      {
        name: 'Интернет',
        slug: 'internet',
        children: [
          { name: 'Посещенные сайты', route: 'websites' },
          { name: 'Использование мессенджеров', route: 'messengers' },
          { name: 'Использование почты', route: 'mail' },
          {
            name: 'Детализация по мессенджерам',
            route: 'detailsMessengers',
          },
        ],
      },
      {
        name: 'Печать на принтерах',
        slug: 'printers',
        children: [
          { name: 'Статистика печати', route: 'printer-statistics' },
          { name: 'По сотрудникам ', route: 'employees' },
          { name: 'По принтерам', route: 'print' },
        ],
      },
      {
        name: 'Микрофон',
        slug: 'microphone',
        children: [
          { name: 'Суммарный', route: 'microphoneSum' },
          { name: 'Детализированный', route: 'microphoneDetails' },
        ],
      },
    ],
  },
  {
    name: 'Безопасность',
    slug: 'security',
    categories: [
      {
        name: 'Карта связей',
        slug: 'relations',
        route: 'security',
      },
    ],
  },
  {
    name: 'Данные',
    slug: 'data',
    categories: [
      {
        name: 'Просмотр',
        slug: 'view',
        children: [
          { name: 'Обычный режим', route: 'computers' },
          { name: 'Продвинутый режим', route: 'advanced' },
        ],
      },
      {
        name: 'Оповещения',
        slug: 'notify',
        route: 'notifications',
      },
      {
        name: 'Формирование задач',
        slug: 'tasks',
        route: 'tasks',
      },
      {
        name: 'Поиск',
        slug: 'search',
        route: 'search',
      },
      {
        name: 'Экран online',
        slug: 'apps',
        route: 'remoteViewer',
      },
    ],
  },
  {
    name: 'Администрирование',
    slug: 'control',
    categories: [
      {
        name: 'Параметры работы сервисов',
        slug: 'settings',
        route: 'general',
      },
      {
        name: 'Пользователи',
        slug: 'people',
        route: 'controls',
      },
      {
        name: 'Настройки сбора данных',
        slug: 'research',
        route: 'agents',
      },
      {
        name: 'Категории сайтов/программ',
        slug: 'category',
        route: 'category',
      },
      {
        name: 'Стоимость печати на принтерах',
        slug: 'printers',
        route: 'printPrice',
      },
    ],
  },
];

export default {
  components: {
    Navigation,
  },
  data: () => ({
    opened: menu.reduce((acc, item) => {
      acc[item.slug] = true;
      return acc;
    }, {}),
  }),
  computed: {
    menu: () => menu,
  },
  methods: {
    toggle(slug) {
      this.opened[slug] = !this.opened[slug];
    },
  },
};
</script>

<style lang="scss" module>
.navigation {
  margin-top: 20px;
  width: 250px;
}
</style>
