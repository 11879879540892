<template>
  <section :class="$style.navigation">
    <header
      :class="$style.header"
      @click="toggle"
    >
      <div :class="$style.headerIcon">
        <img :src="icon(slug)">
      </div>
      <div :class="$style.headerText">
        {{ name }}
      </div>
      <div :class="[$style.arrow, { [$style.expanded]: opened }]">
        <img src="@/assets/images/icons/navigation/arrow.svg">
      </div>
    </header>
    <transition name="transition-fade-in">
      <article
        v-if="opened"
        :class="$style.article"
      >
        <section
          v-for="(category, idx) in categories"
          :key="idx"
          :class="$style.category"
        >
          <component
            :is="category.route ? 'router-link' : 'header'"
            :to="{ name: category.route }"
            :class="$style.categoryHeader"
          >
            <div :class="$style.categoryHeaderIcon">
              <img :src="icon(category.slug)">
            </div>
            <div :class="$style.categoryHeaderText">
              {{ category.name }}
            </div>
            <img
              v-if="category.route === 'notifications' && hasNewNotifications"
              :class="$style.alertCircle"
              :src="require('@/assets/images/icons/navigation/circleAlert.svg')"
            >
          </component>
          <article
            v-if="category.children && category.children.length"
            :class="$style.categoryArticle"
          >
            <div
              v-for="(item, itemIdx) in category.children"
              :key="itemIdx"
              :class="$style.categoryChildLink"
            >
              <router-link
                :to="{ name: item.route }"
                :class="$style.menuItem"
                :exact="!!item.children"
              >
                {{ item.name }}
              </router-link>

              <article
                v-if="item.children && item.children.length"
                :class="$style.categoryArticle"
              >
                <router-link
                  v-for="(child, childIdx) in item.children"
                  :key="childIdx"
                  :to="{ name: child.route }"
                  :class="$style.categoryChildLink"
                >
                  {{ child.name }}
                </router-link>
              </article>
            </div>
          </article>
        </section>
      </article>
    </transition>
  </section>
</template>

<script>
export default {
  props: {
    slug: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    opened: {
      type: Boolean,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    hasNewNotifications() {
      return this.$store.getters['navigation/newNotifications'];
    },
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    },
    icon: (slug) => require(`@/assets/images/icons/navigation/${slug}.svg`),
  },
};
</script>

<style lang="scss" module>
.navigation {
  background: $white;
  padding: 20px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.header {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.headerIcon {
  flex-shrink: 0;
}

.headerText {
  flex-grow: 1;
  font-size: 16px;
  font-weight: bold;
  margin: 0 10px;
}

.arrow {
  transition: transform 0.3s ease-out;
}

.expanded {
  transform: rotate(180deg);
}

.article {
  margin-top: 15px;
}

.category {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.categoryHeader {
  display: flex;
  align-items: center;
  color: $black;
  text-decoration: none;
}

.categoryHeaderIcon {
  flex: 0 0 24px;
}

.categoryHeaderText {
  font-size: 14px;
}

.categoryArticle {
  margin-top: 9px;
}

.categoryChildLink {
  display: block;
  font-size: 14px;
  color: $dark-gray;
  text-decoration: none;
  position: relative;
  margin-left: 24px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0.64em;
    left: -17px;
    width: 10px;
    border-bottom: 1px solid $branchColor;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -17px;
    width: 1px;
    height: 0.64em;
    border-left: 1px solid $branchColor;
  }

  &:not(:last-child)::after {
    height: auto;
    bottom: -10px;
  }
}

.alertCircle {
  padding: 0px 40%;
}

.menuItem {
  display: block;
  font-size: 14px;
  color: #9A99A2;
  text-decoration: none;
  position: relative;
  margin-bottom: 10px;
}
</style>

<style lang="scss">
.router-link-active {
  background-color: #2985bf;
  padding: 4px;
  padding-bottom: 6px;
  color: $white;
  border-radius: 5px;
}
</style>
